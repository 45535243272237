<template>
  <div>
    <SelfServiceDisplayComponent/>
  </div>
</template>

<script>
import SelfServiceDisplayComponent from '@/components/pos/selfservice/SelfServiceDisplayComponent.vue';
export default {
  name: "SelfServiceDisplay",
  components: {
    SelfServiceDisplayComponent
  },
  data() {
    return {
      icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
    }
  },
};
</script>