<template>
  <v-container fluid>
      <v-row dense>
        <v-col v-if="selfServiceCashiers.length<1" cols="12" align="center">{{ $t('generic.lang_nothingtoshow') }}</v-col>
        <v-col v-for="(cashier, index) in selfServiceCashiers" :class="{'highlight-help': cashiersToHelp.includes(cashier.id)}" :key="index" cols="12" sm="6" md="6" lg="6" xl="4">
          <ItemsDisplay :items="cashier.items" :cashier-alias="cashierAliases[cashier.id]" @showVoidDialog="showVoidDialog"/>  
        </v-col>
      </v-row>
      
    <VoidReasons :selected-item="selectedItem" :show-dialog="showVoidReasonsDialog" @closeDialog="closeVoidDialog"
    @selectVoidReason="voidItem" :skip-amount-selection="checkIfAmountSelectionSkip"/>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ItemsDisplay from "./ItemsDisplay.vue";
import VoidReasons from "../VoidReasons";

export default {
  name: "SelfServiceDisplayComponent",
  components: {
    ItemsDisplay,
    VoidReasons
  },
  data() {
    return {
      selfServiceCashiers: [],
      selectedItem: null,
      showVoidReasonsDialog: false,
      shouldSkipSelectedAmout: false,
      cashiersToHelp: "",
    }
  },
  sockets: {
    "pos.selfcheckout.updateItems": function (data) {
      this.updateItems(data.cashierID, data.items);
    },
    "pos.selfcheckout.initBookedItems": function (data) {
      this.updateItems(data.cashierID, data.items);
    },
    "pos.selfcheckout.changePage": function (data) {
      this.removeCashier(data.cashierID);
    },
    "pos.selfcheckout.help": function (data) {
      // show warning message to call help agent
      this.$swal({
        title: this.$t('generic.lang_warning'),
        text: this.$t('generic.lang_selfcheckoutCustomerHelpMSG').replace('{cashierID}', data.cashierID),
        icon: "warning",
        confirmButtonText: 'Ok',
        showCancelButton: false,
        showConfirmButton: true,
        preConfirm: () => {
          this.cashiersToHelp+= data.cashierID + ",";
          setTimeout(() => {
            this.cashiersToHelp = this.cashiersToHelp.replace(data.cashierID + ",", "");
          }, 10000);
        },
        allowOutsideClick: () => false,
      });
    },
    userDisconnected(data){
      this.removeCashier(data.cashierID);
    }
  },
  computed: {
    ...mapGetters({
      getCashiers: 'cashierIDs/cashiers',
    }),
    checkIfAmountSelectionSkip() {
      if (this.selectedItem)
        return !Number.isInteger(this.selectedItem.amount);
      
      return false;
    },
    cashierAliases(){
      const cashierAliases = {}

      this.getCashiers.forEach(cashier => {
        cashierAliases[cashier.id] = cashier.aliasName || this.$t('generic.lang_cashierID') + ' ' + cashier.id
      })

      return cashierAliases;
    }
  },
  methods: {
    removeCashier(cashierID){
      this.selfServiceCashiers = this.selfServiceCashiers.filter(cashier => cashier.id !== cashierID);
    },
    updateItems(cashierID, items){
      const index = this.selfServiceCashiers.findIndex(cashier => cashier.id === cashierID);

      if(index !== -1){
        this.selfServiceCashiers[index].items = items;
      }else {
        this.selfServiceCashiers.unshift({
          id:cashierID,
          items: items
        });
      }
    },
    showVoidDialog(item){
      this.selectedItem = item;
      this.showVoidReasonsDialog = true;
    },
    closeVoidDialog() {
      this.showVoidReasonsDialog = false;
      this.selectedItem = null;
    },
    voidItem(data){
      this.$socket.emit("pos.selfcheckout.voidItem", {position: this.selectedItem.randomPositionID, posType: "retail", ...data})
      this.showVoidReasonsDialog = false;
      this.selectedItem = null;
    }
  },
  mounted(){
    this.$socket.emit("pos.selfcheckout.refreshSelfServiceCashiers");
  },
};
</script>
<style>
.highlight-help {
  border: 4px solid red;
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    border-color: transparent;
  }
}
</style>