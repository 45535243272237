<template>
    <div>
        <v-list-item tile flat class="px-1"
            :style="{ minHeight: '42px', 'background-color': ($vuetify.theme.dark ? '' : (itemIndex % 2 === 1 ? '#ededed' : '')) }">
            <v-list-item-content class=" py-0">
                <v-list-item-title>
                    <v-row class="ma-0 mx-0" no-gutters>
                        <v-col cols="12" md="12" sm="12"
                            :style="{ 'font-size': '14px', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through' : '') }">
                            <!-- Name -->
                            <span
                                :style="{ fontWeight: 'bold', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through' : '') }">
                                {{ item.isWeighted ? 1 : item.amount }}x
                            </span> <span class="text-wrap">{{ itemName }}</span>
                            <span v-if="showGFMin" class="text-wrap"><br>{{ $t('generic.lang_min') }} : {{
                                item.min_redeem_cart_value | currency
                                }}</span>
                            <span class="text-wrap" v-if="this.depositGroup !== null"><br>{{
                                this.depositGroup.name
                                }}</span>
                            <div class="error--text pt-1">{{ item.serialNo }}</div>
                        </v-col>
                    </v-row>
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="!item.isVoid" class="ma-0 pa-0" style="background-color: #FF6969; margin-right: 2px !important;" @click.stop="showVoidDialog">
                <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
                    <v-row no-gutters>
                        <v-col cols="12" align="center" class="text-center white--text">
                            <font-awesome-icon :icon="['fal', 'trash']" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-list-item-action>
            <v-list-item-action class="ma-0 pa-0 ">
                <v-row align="center" class="ma-0 mx-0 pl-1" no-gutters>
                    <v-col class="text-center" align-self="center" cols="12" md="12" sm="12">
                        <span
                            :style="{ fontSize: '14px !important', fontFamily: 'Anton', float: 'right', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through' : '') }">
                            <template v-if="!item.isWeighted">
                                {{ itemSellPrice | currency }}
                                <span style="color: #3F6AD8;"></span>/
                            </template>
                            {{ itemStackSellPrice | currency }}

                            <span v-if="item.discount > 0" style="color: #D92550;">({{ item.discount }}%)</span>
                        </span>
                    </v-col>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="item.isPriceChanged" class="px-0" :style="{ minHeight: '20px' }">
            <v-list-item-action class="pa-0 ma-0" style="width: 100% !important;">
                <v-row no-gutters>
                    <v-col cols="12" align="end">
                        <v-card dark elevation="0" class="pa-0 px-2 text-left rounded-br-xl"
                            style="font-size: 0.8em !important;width: fit-content;" color="error">
                            {{ $t('generic.lang_originalPrice') + " : " }} {{ item.originalSellPrice | currency }}
                        </v-card>
                    </v-col>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <div style="height: 1px; border-top: 1px dashed #b4b4b4; "></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { roundNumber } from "@/plugins/round";
import { format } from "mathjs";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
} from '@fortawesome/pro-light-svg-icons';

library.add(faTrash);

export default {
    name: "Item",
    components:{
        FontAwesomeIcon   
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        itemIndex: Number,
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState([
            'pos',
            'mealsizes',
            'deposit'
        ]),
        showGFMin() {
            return this.item?.ean?.includes("GF$") && this.item?.hasOwnProperty('min_redeem_cart_value') && this.item.min_redeem_cart_value > 0;
        },
        depositGroup() {
            if (this.item.depositgroupID === 0)
                return null;

            //FIND DEPOSIT GROUP
            let depositGroup = this.deposit.deposits.find((depositGroup) => {
                return depositGroup.id === this.item.depositgroupID;
            });

            if (!depositGroup)
                return null;

            return depositGroup;
        },
        itemName() {
            if (this.item.isVariation) {
                return this.item.name + " " + this.item.variationSize + " " + this.item.variationColor;
            }

            //CHECK IF ITEM HAS MEALSIZE ID
            if (this.item.mealSizeID > 0) {
                //FIND MEALSIZE
                let mealsize = this.mealsizes.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

                if (mealsize)
                    return this.item.name + " " + mealsize.name;
            }

            return this.item.name;
        },
        itemSellPrice() {
            let sellPrice = this.item.sellPrice;
            // -----------------------------------------------------
            // --------------------- DISCOUNT -----------------------
            //Discount ONLY IN %
            if (this.item.sellPrice === this.item.originalSellPrice && this.item.discount > 0) {
                //sellPrice = ((100 - this.item.discount) / 100) * sellPrice;
                //sellPrice =  parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
                sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));

                sellPrice = Number(format(sellPrice, { precision: 14 }));

            }

            //DISCOUNT WITH PRICE CHANGE
            if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount === 0) {
                sellPrice = 1 * sellPrice;
            }

            //DISCOUNT IN % AND PRICECHANGE
            if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount > 0) {
                //sellPrice = 1 * (((100 - this.item.discount) / 100) * sellPrice)
                //sellPrice =  parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
                sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));
                sellPrice = Number(format(sellPrice, { precision: 14 }));
            }
            // -----------------------------------------------------

            //CHECK DEPOSIT
            if (this.depositGroup !== null) {
                sellPrice += this.depositGroup.priceTotal;
            }

            return sellPrice;
        },
        itemStackSellPrice() {
            let weightCalc = 0.00;
            weightCalc = Number(this.itemSellPrice) * this.item.amount
            weightCalc = roundNumber(weightCalc, 2);

            return (weightCalc).toFixed(2);
        },
    },
    methods: {
        showVoidDialog() {
            this.$emit('showVoidDialog', this.item);
        },
    }
}
</script>