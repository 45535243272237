<template>
  <v-card flat>
    <v-toolbar>
      <div v-if="!this.$vuetify.breakpoint.smAndDown" class="my-auto" style="font-size: 0.9em;font-weight: bolder;">
        <div class="small">{{ api.auth.posID + ' - ' + settings.settings.system_name }}</div>
        <div class="small">{{ cashierAlias }}</div>
        <!-- <div class="small">{{ this.currentTime }}</div> -->
      </div>
      <v-spacer />
      <h6 class="text-muted text-center">
        {{ $t('erp.lang_quantity') }}: {{ itemsCount }}
      </h6>
      <v-spacer />
      <div class="d-flex flex-column align-center">
        <p class="subTotalPrice ma-0 mt-2 "
          v-if="$vuetify.breakpoint.mdAndUp && Number($store.getters['settings/getSettingValue']('systemDisplaySubtotal'))">
          {{ $t('generic.lang_subtotal') }}: {{ subTotalPrice | currency }}
        </p>
        <p class="totalPrice ma-0 mb-1">
          {{ $t('generic.lang_total') }}: {{ totalPrice | currency }}
        </p>
      </div>
    </v-toolbar>

    <div class="overflow-auto" ref="scrollList" id="scrollList">
      <v-divider class="ma-0" />
      <v-list name="itemList" id="booked-items-list" max-height="400" height="400">
        <!-- Show Vouchers / Giftcards always on top -->
        <Item :id="'booked_' + item.randomPositionID" v-for="(item, index) in orderedVouchers" @showVoidDialog="showVoidDialog"
          :key="'booked_' + item.randomPositionID" :item="item" :item-index="index" />

        <!-- THEN OUR ITEMS -->
        <Item :id="'booked_' + item.randomPositionID" v-for="(item, index) in orderedItemsOnly" @showVoidDialog="showVoidDialog"
          :key="'booked_' + item.randomPositionID" :item="item" :item-index="index" />
      </v-list>
    </div>
  </v-card>
</template>

<script>
import Item from './Item.vue';
import { roundNumber } from "@/plugins/round";
import { format } from "mathjs";
import { mapState } from 'vuex/dist/vuex.common.js';
import { getNetPrice } from "@/plugins/tax";

export default {
  name: "ItemsDisplay",
  components: {
    Item
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    cashierAlias: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState([
      "api",
      "settings",
      "deposit",
    ]),
    itemsCount() {
      return this.items.reduce((acc, item) => {
        if (!item.isVoid) {
          if (Number(item.amount) === item.amount && item.amount % 1 !== 0) {
            acc += 1;
          } else {
            acc += item.amount;
          }
        }
        return acc;
      }, 0);
    },
    orderedItemsOnly() {
      return this.items.filter((orderedItem) => {
        if (!orderedItem.ean.includes("V$") && !orderedItem.ean.includes("GF$") && !orderedItem.ean.includes("ZMYLE$") && !orderedItem.ean.includes("MYWORLD$")) {
          return true;
        }
      });
    },
    orderedVouchers() {
      return this.items.filter((orderedItem) => {
        if (orderedItem.ean.includes("V$") || orderedItem.ean.includes("GF$") || orderedItem.ean.includes("ZMYLE$") || orderedItem.ean.includes("MYWORLD$")) {
          return true;
        }
      });
    },
    totalPrice() {
      let total = 0;

      this.items.forEach((item) => {
        let itemPrice = 0;

        //SKIP IF VOIDED
        if (item.isVoid || item.amount === 0) return;

        //CHECK DISCOUNT
        let discount = 0;
        if (item.hasOwnProperty("discount")) discount = item.discount;

        //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        itemPrice = parseFloat(
          ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) * 100) /
          100
        );

        itemPrice = format(itemPrice, { precision: 14 });
        itemPrice = roundNumber(itemPrice, 2);

        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
          if (item.depositgroupID > 0) {
            let deposit = this.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (deposit) itemPrice += deposit.priceTotal;
          }
        }

        let weightCalc = 0.0;
        weightCalc = parseFloat(itemPrice * item.amount);
        weightCalc = format(weightCalc, { precision: 14 });
        weightCalc = roundNumber(weightCalc, 2);

        total += parseFloat(weightCalc);
        total = format(total, { precision: 14 });
        total = roundNumber(total, 2);
      }, 0);

      return total;
    },
    subTotalPrice() {
      let total = 0;

      this.items.forEach((item) => {
        let itemPrice = 0;

        //SKIP IF VOIDED
        if (item.isVoid || item.amount === 0) return;

        //CHECK DISCOUNT
        let discount = 0;
        if (item.hasOwnProperty("discount")) discount = item.discount;

        //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));

        if (
          item.sellPrice < 0 &&
          (item.ean.includes("V$") || item.ean.includes("GF$"))
        ) {
          item.netPrice = item.sellPrice;
        } else {
          item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
        }

        itemPrice = Number(
          ((item.netPrice * ((100 - discount) / 100) + Number.EPSILON) * 100) /
          100
        );

        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
          if (item.depositgroupID > 0) {
            let deposit = this.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (deposit) itemPrice += deposit.priceTotal;
          }
        }

        let weightCalc = itemPrice * item.amount;

        total += weightCalc;
      }, 0);

      return roundNumber(total, 2);
    },
  },
  methods: {
    showVoidDialog(item) {
      this.$emit('showVoidDialog', item);
    },
  }
}

</script>

<style>
.totalPrice {
  font-family: ANTON;
  font-weight: bold;
  color: #D92550;
  letter-spacing: 1px;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.subTotalPrice{
  font-size: 16px;
  font-family: ANTON;
  font-weight: bold;
  color: #0f0f0f;
  letter-spacing: 1px;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
</style>